<template>
  <div class="ministry_wrapper">
    <b-container fluid>
      <!-- Accordion Start -->
      <b-row>
        <b-col sm="12">
          <div class="accordion" role="tablist">
            <!-- Seed Wrapper -->
            <div class="accordion-item seed_wrapper">
              <h2 class="accordion-header" header-tag="header" ref="seed">
                <b-button block v-b-toggle.seed class="accordion-button accordion_seed">
                  <div class="header_inner">
                    <img src="../../../../../../assets/images/ministry-dashboard/seed.svg" class="item_icon">
                    <span class="title title_seed">{{ $t('seedsSeeds.seed') }}</span>
                  </div>
                </b-button>
              </h2>
                <div class="accordion-body seed">
                  <Seed></Seed>
                </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <!-- Accordion End -->
    </b-container>
  </div>
</template>
<script>
//   import RestApi, { commonServiceBaseUrl } from '@/config/api_config'
//   import { mapGetters } from 'vuex'
  import Seed from './Seed'

  export default {
    components: {
      Seed
    },
    data () {
      return {
        styleObject: {
          scrollMarginTop: '-220px'
        },
        dashboardNames: {
          seed: ''
        }
      }
    },
    computed: {
    },
    watch: {
      defaultNavbarHeight: function (newVal, oldVal) {
        this.setPageTopMargin(newVal, oldVal)
      }
    },
    created () {
    },
    methods: {
    }
  }
</script>
