<template>
    <div>
        <b-overlay :show="loaderPie">
            <!-- <b-row v-if="Object.keys(data).length > 0"> -->
            <div class="col-12">
                <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                        <b-row>
                            <b-col lg="3" md="3" sm="12">
                                <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="fiscal_year_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{ $t('seedsSeeds.fiscal_year') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="search.fiscal_year_id"
                                    :options="fiscalList"
                                    id="fiscal_year_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <ValidationProvider name="Organization"  vid="org_id">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="12"
                                        label-for="org_id"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{ $t('warehouse_config.organization') }}
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="search.org_id"
                                        :options="orgList"
                                        id="org_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                        </b-form-select>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <ValidationProvider name="Office Type"  vid="office_type_id">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="12"
                                        label-for="office_type_id"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{ $t('seedsSeeds.office_type') }}
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="search.office_type_id"
                                        :options="officeTypeList"
                                        id="office_type_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                        </b-form-select>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <ValidationProvider name="Office"  vid="spc_office_id">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="12"
                                        label-for="spc_office_id"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{ $t('seedsSeeds.office_name') }}
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="search.spc_office_id"
                                        :options="officeList"
                                        id="spc_office_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                        </b-form-select>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <ValidationProvider name="Seed Type"  vid="crop_type_id">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="12"
                                        label-for="crop_type_id"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{ $t('seedsConfig.cropType') }}
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="search.crop_type_id"
                                        :options="cropTypeList"
                                        id="crop_type_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                        </b-form-select>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <ValidationProvider name="crop Name"  vid="crop_name_id">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="12"
                                        label-for="crop_name_id"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{ $t('seedsConfig.cropName') }}
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="search.crop_name_id"
                                        :options="cropNameList"
                                        id="crop_name_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                        </b-form-select>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <div class="btn_filter_wrapper" style="margin-top:15%">
                                    <button type="submit" class="btn btn-primary" @click="searchData">{{ $t('globalTrans.search') }}</button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-form>
                </ValidationObserver>
            </div>
            <hr/>
            <br/>
            <b-row>
                <b-col sm="3">
                    <div class="card_item_wrapper production_wrapper">
                        <div class="number_wrapper">
                            <p class="number">
                                <img src="../../../../../../assets/images/ministry-dashboard/production.svg" class="item_icon img-fluid">
                            </p>
                        </div>
                        <div class="card_item">
                            <p class="title">{{ $t('ministryDashboard.production') }}</p>
                            <h2 class="count production_count">{{$n(proudctionMetrict(productionQnty))}}</h2>
                        </div>
                    </div>
                </b-col>
                <b-col sm="3">
                    <div class="card_item_wrapper allocation_wrapper">
                        <div class="number_wrapper">
                            <p class="number">
                                <img src="../../../../../../assets/images/ministry-dashboard/allocation.svg" class="item_icon img-fluid">
                            </p>
                        </div>
                        <div class="card_item">
                            <p class="title">{{ $t('ministryDashboard.allocation') }} <span>{{ $t('globalTrans.m_ton') }}</span></p>
                            <h2 class="count production_count">{{$n(allocationMetric(allocationQnty))}}</h2>
                        </div>
                    </div>
                </b-col>
                <b-col sm="3">
                    <div class="card_item_wrapper sale_wrapper">
                        <div class="number_wrapper">
                            <p class="number">
                                <img src="../../../../../../assets/images/ministry-dashboard/sale.svg" class="item_icon img-fluid">
                            </p>
                        </div>
                        <div class="card_item">
                            <p class="title">{{ $t('ministryDashboard.sale') }}</p>
                            <h2 class="count production_count">{{$n(salesMetric(saleQnty))}}</h2>
                        </div>
                    </div>
                </b-col>
                <b-col sm="3">
                    <div class="card_item_wrapper stock_wrapper">
                        <div class="number_wrapper">
                            <p class="number">
                                <img src="../../../../../../assets/images/ministry-dashboard/stock.svg" class="item_icon img-fluid">
                            </p>
                        </div>
                        <div class="card_item">
                            <p class="title">{{ $t('ministryDashboard.stock') }}</p>
                            <h2 class="count production_count">{{$n(stockMetric(stockQnty))}}</h2>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="6">
                    <div class="grapChart_wrapper" style="height: 420px !important">
                        <h5 class="card_title">{{ $t('ministryDashboard.total_production_sale_status') }}</h5>
                        <apexchart  type="line" width="480" v-if="prodCheck" :options="lineOption" :series="lineSeriesData"></apexchart>
                        <!-- <img src="../../../../../../assets/images/line-graph.jpg" class="img-fluid w-100"> -->
                    </div>
                </b-col>
                <b-col sm="6">
                    <div class="grapChart_wrapper" style="height: 420px !important">
                        <h5 class="card_title">{{ $t('ministryDashboard.total_production_status') }}</h5>
                         <!-- <img src="../../../../../../assets/images/pie-chart.png" class="img-fluid w-100"> -->
                        <apexchart type="pie" width="380" v-if="searchThis" :options="pieOption" :series="series()"></apexchart>
                    </div>
                </b-col>
            </b-row>
        </b-overlay>
    </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { seedMainDashboard } from '../../api/routes'

export default {
    data () {
    return {
    loaderPie: true,
    searchThis: false,
    prodCheck: false,
    data: {},
    pieSeriesData: [],
    productionValues: [],
    saleValues: [],
    stockValues: [],
    lineSeriesData: [],
    firstLabel: ['Sale(M.Ton)', 'Stock(M.Ton)'],
    firstLabelBn: ['বিক্রয়(মে.টন)', 'স্টক(মে.টন)'],
    chartLabel: ['Test'],
    chartLabelBn: ['TestBn'],
    search: {
        fiscal_year_id: 0,
        org_id: 0,
        office_type_id: 0,
        spc_office_id: 0,
        crop_type_id: 0,
        crop_name_id: 0
    },
    cropNameList: [],
    officeTypeList: [],
    officeList: [],
    productionQnty: 0,
    allocationQnty: 0,
    saleQnty: 0,
    stockQnty: 0
    }
    },
    computed: {
        currentFiscalYearId: function () {
            return this.$store.state.SeedsFertilizer.currentFiscalYearId
        },
        cropTypeList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
        },
        orgList: function () {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        },
        fiscalList: function () {
            return this.$store.state.commonObj.fiscalYearList
        },
        monthList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.dashboardMonthList
        },
        currentLocale: function () {
            return this.$i18n.locale
        },
        pieOption () {
          return {
            chart: {
              width: 380,
              type: 'pie'
            },
            labels: this.$i18n.locale === 'bn' ? this.firstLabelBn : this.firstLabel,
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 380
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          }
        },
        lineOption () {
            return {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [5, 7, 5],
                curve: 'straight',
                dashArray: [0, 8, 5]
            },
            legend: {
                tooltipHoverFormatter: function (val, opts) {
                    return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                }
            },
            markers: {
                size: 0,
                hover: {
                sizeOffset: 6
                }
            },
            xaxis: {
                categories: this.monthList.map(item => {
                    return this.$i18n.locale === 'bn' ? item.text_bn : item.text_en
                })
            },
            grid: {
                borderColor: '#f1f1f1'
                }
            }
        }
    },
    watch: {
       currentLocale (newVal, oldVal) {
           if (newVal !== oldVal) {
               this.setLineSeriesData()
           }
       },
        'search.crop_type_id': function (newVal, oldVal) {
            if (newVal) {
                this.cropNameList = this.getCropNameList(newVal)
            } else {
                this.cropNameList = []
            }
        },
        'search.org_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.officeTypeList = this.getOfficeTypeList(newVal)
                this.search.office_type_id = this.$store.state.dataFilters.officeTypeId
            } else {
                this.officeTypeList = []
            }
        },
        'search.office_type_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.officeList = this.getOfficeList(newVal)
            } else {
                this.officeList = []
            }
        }
    },
    created () {
      this.search.org_id = this.$store.state.dataFilters.orgId
      this.search = Object.assign({}, this.search, { fiscal_year_id: this.currentFiscalYearId })
      this.loadData()
    },
    mounted () {
      core.index()
    },
    methods: {
        searchData () {
            this.loadData()
        },
        series () {
            return this.pieSeriesData
        },
        loadData () {
            this.loaderPie = true
            const params = Object.assign({}, this.search)
             RestApi.getData(seedFertilizerServiceBaseUrl, seedMainDashboard, params).then((response) => {
                if (response.success) {
                    this.pieSeriesData = []
                    this.data = []
                    this.searchThis = true
                    this.data = this.getMonthData(response.data)
                    this.productionQnty = response.data.production.productionQnty
                    this.allocationQnty = response.data.allocation.allocationQnty
                    this.saleQnty = response.data.sale.saleQnty
                    this.stockQnty = response.data.stock
                    this.pieSaleQnty = parseFloat(this.saleQnty) / 100
                    this.pieStockQnty = parseFloat(this.stockQnty) / 100
                    this.pieSeriesData.push(this.pieSaleQnty)
                    this.pieSeriesData.push(this.pieStockQnty)
                } else {
                    this.data = {}
                }
                this.loaderPie = false
            })
        },
        getMonthData (data) {
            this.productionValues = []
            this.saleValues = []
            this.stockValues = []
            this.monthList.map(item => {
                const production = data.lineProduction.find(production => production.month === item.value)
                    if (typeof production !== 'undefined') {
                        this.productionValues.push(production.productionQnty)
                    } else {
                        this.productionValues.push(0)
                    }
                const sale = data.lineSale.find(sale => sale.month === item.value)
                    if (typeof sale !== 'undefined') {
                        this.saleValues.push(sale.saleQnty)
                    } else {
                        this.saleValues.push(0)
                    }
                const stock = data.lineStock.find(stock => stock.month === item.value)
                    if (typeof stock !== 'undefined') {
                        if (stock.stockQuantity > 0) {
                            this.stockValues.push(stock.stockQuantity)
                        } else {
                            this.stockValues.push(0)
                        }
                } else {
                    this.stockValues.push(0)
                }
            })
            this.setLineSeriesData()
            this.prodCheck = true
        },
        proudctionMetrict (totalAmount) {
            return (totalAmount / 1000).toFixed(2)
        },
        allocationMetric (totalAmount) {
            return (totalAmount / 1000).toFixed(2)
        },
        salesMetric (totalAmount) {
            return (totalAmount / 1000).toFixed(2)
        },
        stockMetric (totalAmount) {
            return (totalAmount / 1000).toFixed(2)
        },
        setLineSeriesData () {
            this.lineSeriesData = [
                {
                    name: this.$t('ministryDashboard.production'),
                    data: this.productionValues
                },
                {
                    name: this.$t('ministryDashboard.sale'),
                    data: this.saleValues
                },
                {
                    name: this.$t('ministryDashboard.stock'),
                    data: this.stockValues
                }
            ]
        },
        getCropNameList (croptypeID) {
            const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1 && item.crop_type_id === croptypeID)
            return cropNameList
        },
        getOfficeTypeList (orgId = null) {
            const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
                if (orgId) {
                    return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
                        if (this.$i18n.locale === 'bn') {
                            return { value: obj.value, text: obj.text_bn }
                        } else {
                            return { value: obj.value, text: obj.text }
                        }
                    })
                }
                return officeTypeList
        },
        getOfficeList (officeTypeId) {
            const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
            if (officeTypeId) {
                return officeList.filter(item => item.office_type_id === officeTypeId)
            }
            return officeList
        }
    }
}
</script>
<style scoped>
    .hrclass {
        border-top: 1px solid rgb(96, 149, 19) !important;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }
    .info h4 {
        font-size: 26px;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
    .info > p {
        color: #717171;
        font-size: 16px;
        padding-top: 10px;
        text-align: justify;
    }
</style>
